/**
 * Helpers
 */
import {
  fromBreakpoint,
  onVisibilityChange,
  toBreakpoint,
} from './core/helpers'

/**
 * Web standards
 */
import './theme/Accordion' // TODO remove
import './core/cookieconsent'
import './core/softscroll'
import './core/lazyload'
import { modal } from './core/modal'
import 'alpinejs/dist/cdn.min.js'
import collapse from '@alpinejs/collapse'

Alpine.plugin(collapse)

/**
 * Scripts which don't need dynamic import
 */
let wpcf7forms = document.querySelector('.wpcf7-form')
if (wpcf7forms) {
  const jquery = import('jquery')
  global.jQuery = require('jquery')
  window.jQuery = require('jquery')
}

/**
 * Add scripts or styles based on loaded DOM
 */
addEventListener('DOMContentLoaded', () => {
  if (document.querySelectorAll('div[data-modal-target]').length > 0) {
    modal.init()
  }
})

/**
 * Dynamic import scripts
 */
const elements = ['[data-modal-target]', '.splide', '[data-aos]', '.timeline-wrapper']

;[].forEach.call(elements, (element) => {
  if (element !== '') {
    if (
      document.querySelector(element) !== null &&
      document.querySelector(element) !== undefined
    ) {
      const lazyloadHandler = onVisibilityChange(
        document.querySelector(element),
        function () {
          if (element === '[data-modal-target]') {
            modal.init()
          }
          if (element === '.splide') {
            const splide = import('./vendor/splide')
          }

          if (element === '.timeline-wrapper') {
            const timeline = import('./vendor/timeline')
          }

          if (element === '[data-aos]') {
            const AOS = import('aos').then((AOS) => {
              AOS.init({
                offset: 60,
  duration: 400, // values from 0 to 3000, with step 50ms
  easing: 'ease-in-out', // default easing for AOS animations
                disable: function () {
                  return /bot|googlebot|crawler|spider|robot|crawling/i.test(
                    navigator.userAgent
                  )
                },
              })
            })
          }
        }
      )

      if (window.addEventListener) {
        addEventListener('DOMContentLoaded', lazyloadHandler, false)
        addEventListener('load', lazyloadHandler, false)
        addEventListener('scroll', lazyloadHandler, false)
        addEventListener('resize', lazyloadHandler, false)
      } else if (window.attachEvent) {
        attachEvent('onDOMContentLoaded', lazyloadHandler) // Internet Explorer 9+ :(
        attachEvent('onload', lazyloadHandler)
        attachEvent('onscroll', lazyloadHandler)
        attachEvent('onresize', lazyloadHandler)
      }
    }
  }
})
